<template>
  <div class="container">
    <h4 class="text-center my-4">Утверждение майнора</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <div class="mt-4">



        <section>

          <ul v-if="chooseDiscipline.education_programs.length > 0">
            Привязанные ОП:
            <li v-for="(item, index) in chooseDiscipline.education_programs"
                :key="index" class="mt-4">

              <a class="p-link" @click="openStudentsDialog(item.education_program_id)">{{ item.education_speciality_code }} {{ item.education_speciality_name }} </a> - {{ item.admission_year }}
            </li>
          </ul>

          <br>
          <br>

          <ul v-if="chooseDiscipline.disciplines.length > 0">
            Привязанные дисциплины:
            <li v-for="(item, index) in chooseDiscipline.disciplines"
                :key="index" class="mt-4">
              {{ item.education_discipline_name }}
            </li>
          </ul>

          <strong>Количество мажор: {{chooseDiscipline.major_count}}</strong>
          <br>
          <strong>Количество майнор: {{chooseDiscipline.minor_count}}</strong>

          <div class="text-center">
            <Button icon="pi pi-save" :label="chooseDiscipline.confirm_status == 1 ? 'Утвержден' : 'Утвердить' "  class="p-button-rounded"
                    :loading="loadingButton" @click="confirmMinor()" :disabled="chooseDiscipline.confirm_status == 1"/>
          </div>

        </section>


        <!-- disciplines -->
        <Dialog header="Студенты" :visible="displayStudentsDialog" :closable="false" :modal="true"
                :style="{width: '100%', maxWidth: '900px'}">
          <div class="my-3">
            <div class="row my-2">


              <div v-if="students.length > 0">
                <DataTable :value="students" :paginator="true" :rows="10" showGridlines stripedRows responsiveLayout="scroll">

                  <Column field="barcode" header="Баркод"></Column>

                  <Column header="ФИО">
                    <template #body="{data}">
                      {{data.last_name}} {{data.first_name}}
                    </template>
                  </Column>
                  <Column field="minor_name" header="Название майнора"></Column>
                  <Column field="education_speciality_name" header="ОП"></Column>
                </DataTable>
              </div>

              <div v-else>
                <p>Студентов нет</p>
              </div>




            </div>

          </div>
          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" @click="closeStudentsDialog" class="p-button-text"/>
          </template>
        </Dialog>
        <!-- end disciplines -->





        <!-- Table Start -->
<!--        <DataTable :value="studentsList_form.studentsListInfos" :paginator="true" :rows="10"-->
<!--                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"-->
<!--                   currentPageReportTemplate="Показано {last} из {totalRecords}"-->
<!--                   showGridlines stripedRows responsiveLayout="scroll">-->

<!--          <Column field="barcode" header="Баркод">-->
<!--            <template #body="{data}">-->
<!--              <router-link :to="'/student/my-profile?student_id=' + data.id" target="_blank"-->
<!--                           class="text-decoration-none">-->
<!--                {{data.barcode}}-->
<!--              </router-link>-->

<!--            </template>-->
<!--          </Column>-->

<!--          <Column field="studyLevel.name" header="Уровень обучения">-->
<!--            <template #body="{data}">-->
<!--              <a href="#" @click="editStudentStudyLevel(data.id)">-->
<!--                {{ data.studyLevel.name }}-->
<!--              </a>-->
<!--            </template>-->

<!--          </Column>-->
<!--          -->
<!--        </DataTable>-->
        <!-- Table End -->



      </div>
    </div>


  </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "MinorEducationProgramConfirm",
  data() {
    return {
      loading: true,

      loadingButton: false,
      urlMinorEducationProgramId: this.$route.query.minor_education_program_id || 0,
      displayStudentsDialog: false,
      students: []

    }
  },
  computed: {
    ...mapState('minorEducationProgram', ['chooseDiscipline']),
  },

  methods: {
    ...mapActions('minorEducationProgram', ['GET_CHOOSE_DISCIPLINE', 'POST_MINOR_EDUCATION_PROGRAM_CONFIRM', 'GET_MINOR_STUDENTS']),
    //...mapMutations('minorEducationProgram', ['SET_OBJECT_UNIVERSITY_DOCUMENT_NULL']),

    async confirmMinor() {
      this.loadingButton = true

      const form = {
        minor_education_program_id: this.urlMinorEducationProgramId
      }
      const res = await this.POST_MINOR_EDUCATION_PROGRAM_CONFIRM(form)

      if (res) {
        await this.GET_CHOOSE_DISCIPLINE(this.urlMinorEducationProgramId)
        this.$message({title: 'Успешно сохранено'})
      }

      this.loadingButton = false
    },
    async openStudentsDialog(education_program_id) {
      this.displayStudentsDialog = true
      this.students = await this.GET_MINOR_STUDENTS({education_program_id: education_program_id, minor_education_program_id: this.urlMinorEducationProgramId})
    },
    closeStudentsDialog() {
      this.displayStudentsDialog = false
      this.students = []
    }
  },
  async mounted() {
    console.log(this.$route.query)
    await this.GET_CHOOSE_DISCIPLINE(this.urlMinorEducationProgramId)
    this.loading = false

  }
}
</script>

<style scoped>

</style>
